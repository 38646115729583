var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"time-slot-menu-item",class:{
    'time-slot-menu-item-separator': _vm.isSeparator,
    'time-slot-menu-item-has-icon': _vm.isIconVisible,
    'time-slot-menu-item-no-icon': !_vm.isIconVisible,
    'time-slot-menu-item-has-submenu': _vm.hasSubmenu,
    'time-slot-menu-item-no-submenu': !_vm.hasSubmenu,
    'time-slot-menu-item-submenu-open': _vm.isSubmenuOpen,
  },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick($event)}}},[_c('div',{staticClass:"icon"},[(_vm.isIconVisible)?_c(_vm.iconName,{tag:"component"}):_vm._e()],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.hasSubmenu)?_c('div',{staticClass:"icon icon-submenu"},[_c('icon-time-slot-has-submenu')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }