


import {Component, Vue, Watch} from 'vue-property-decorator';
import { GOOGLE_OAUTH_CLIENT_ID } from '@/_components/oauth-buttons/oauth-buttons.vue';
import {Action, Getter} from 'vuex-class';
import {TEvent} from '@/_types/event.type';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import {TTimeSlot} from '@/_types/meeting/meeting.type';
import {TUser} from '@/_types/user.type';
import {TRequestMeetingParams} from '@/_api/meetings/meetings.api';
import DateTimeHelper from '@/_helpers/date-time.helper';
import IconSettings from '@/_modules/icons/components/icon-settings.vue';
import TimeSlotMenuItem, {
  TimeSlotMenuItemImportedIcons
} from '@/_modules/meetings/components/time-slot-menu-item/time-slot-menu-item.vue';
import {TTimeSlotMenuItem} from '@/_modules/meetings/components/time-slot-menu/time-slot-menu.vue';
import IconMeetingGoogleCalendar from '@/_modules/icons/components/icon-meeting-google-calendar.vue';

const IS_TEST_MODE = false;
const ENVIRONMENT_NAME = process.env.VUE_APP_ENV;
export const FALLBACK_GCAL_TIME_SLOT_TITLE = 'Blocked time slot based on Google Calendar';

// manage by matvey@gmail.com at https://console.cloud.google.com/apis/credentials/key/e4ff5b51-332a-470d-8432-0b186bb52852?project=matvey-temporary-keys
const GOOGLE_API_KEY_FOR_CALENDAR = 'AIzaSyAa5qg9hqBj87je-bUfiTOjrQHbrh0x6B0'; // TODO: switch to the same key as Google Maps
const GOOGLE_OAUTH_CLIENT_ID_TEST = '781650325147-ifsg4o68buqimmmikdol28qpcpsn9gre.apps.googleusercontent.com';
const GAPI_SCRIPT_SRC = 'https://apis.google.com/js/api.js';
const GIS_SCRIPT_SRC = 'https://accounts.google.com/gsi/client';

interface IGoogleTokenClientCallbackResponse {
  error: any;
}

type TGoogleTokenClientCallbackRequestAccessTokenParams = {
  prompt: string;
}

interface IGoogleTokenClient {
  callback?: (response: IGoogleTokenClientCallbackResponse) => Promise<void>;
  requestAccessToken?: (params: TGoogleTokenClientCallbackRequestAccessTokenParams) => void;
}

type TGoogleCalendarEvent = {
  kind?: string;
  etag?: string;
  id?: string;
  status?: string;
  htmlLink?: string;
  created?: string;
  updated?: string;
  summary: string;
  creator?: {
    email?: string;
    self?: boolean;
  };
  organizer: {
    email?: string;
    self?: boolean;
  };
  start: {
    dateTime?: string;
    date?: string;
    timezone?: string;
  };
  end: {
    dateTime?: string;
    date?: string;
    timezone?: string;
  };
  iCalUID?: string;
  sequence?: number;
  reminders?: {
    useDefault?: boolean;
  };
  eventType?: string;
}

enum GCalImportDialogScreens {
  FETCHING_GCAL_EVENTS = 'fetching-google-calendar-events',
  FETCHING_FAILED = 'fetching-google-calendar-failed',
  FOUND_GCAL_EVENTS = 'found-google-calendar-events',
  SETTING_UNAVAILABLE_SLOTS = 'setting-unavailable-slots',
  FINISHED_SETTING_UNAVAILABLE_SLOTS = 'finished-setting-unavailable-slots',
}

@Component({
  components: {
    SimplePopup,
    EwButton,
    IconSettings,
    TimeSlotMenuItem,
    IconMeetingGoogleCalendar,
  }
})
export default class GoogleCalendarMenu extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_userStore/user') user: TUser;

  @Action('meetingsStore/requestMeeting') requestMeeting: (params: TRequestMeetingParams) => Promise<void>;

  public menuItems: TTimeSlotMenuItem[] = [
    {
      name: 'googleCalendarFeatures',
      title: 'Google Calendar',
      isSubmenuOpen: false,
      iconName: TimeSlotMenuItemImportedIcons.GOOGLE_CALENDAR,
      children: [
        {
          name: 'googleCalendarImportBusySlots',
          title: this.$t('meetingsSettingsMenu.googleCalendarImportBusySlots') as string,
          iconName: TimeSlotMenuItemImportedIcons.LOCK_AND_UNLOCK,
        },
      ]
    },
  ];

  public tokenClient: IGoogleTokenClient = {
    callback: null,
    requestAccessToken: null,
  };

  public importDialogScreens: typeof GCalImportDialogScreens = GCalImportDialogScreens;
  public importDialogActiveScreen: GCalImportDialogScreens = GCalImportDialogScreens.FETCHING_GCAL_EVENTS;
  public timeSlots: TTimeSlot[] = [];
  public isGoogleAccessGiven: boolean = false;
  public isGoogleAuthorizeButtonVisible: boolean = false;
  public googleScriptSrcList: string[] = [GAPI_SCRIPT_SRC, GIS_SCRIPT_SRC];
  public CLIENT_ID: string = IS_TEST_MODE ? GOOGLE_OAUTH_CLIENT_ID_TEST : GOOGLE_OAUTH_CLIENT_ID;
  public API_KEY: string = GOOGLE_API_KEY_FOR_CALENDAR;
  public DISCOVERY_DOC: string = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  public SCOPES: string = 'https://www.googleapis.com/auth/calendar.readonly'; // separate by spaces
  public isGapiInitialized: boolean = false;
  public isGoogleIdentityServiceInitialized: boolean = false;
  public previousTokenCallbackResponse: IGoogleTokenClientCallbackResponse = null;
  public importErrorMessage: string = '';
  public foundGoogleCalendarEvents: TGoogleCalendarEvent[] = [];
  public isImportDialogVisible: boolean = false;
  public requestQueueParams: TRequestMeetingParams[] = [];
  public requestingTimeSlotParamsIndex: number = 0;
  public isSettingsMenuVisible: boolean = false;
  public settingsButtonMode: string = 'text-link';
  public ignoreClickOutside: boolean = false;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get userId(): number {
    return (this.user && this.user.id) || null;
  }

  public get processedSlotsPercentage(): number {
    if (!this.requestQueueParams || this.requestQueueParams.length) {
      return 0;
    }
    return Math.min(this.requestingTimeSlotParamsIndex / this.requestQueueParams.length * 100, 100);
  }

  public get isComponentVisible(): boolean {
    return !this.isCN;
  }

  public get isCN(): boolean {
    return (ENVIRONMENT_NAME === 'cn');
  }

  public get eventTimezone(): string {
    return (this.event && this.event.time_region) || '';
  }

  public get timeMinFormatted(): string {
    const now = new Date();
    const eventStart = this.event.date_start;
    if (now.getTime() > eventStart.getTime()) {
      return now.toISOString();
    }
    return this.eventDateStartFormatted;
  }

  public get eventDateStartFormatted(): string {
    return ((this.event && this.event.date_start) || (new Date())).toISOString();
  }

  public get eventDateEndFormatted(): string {
    const date: Date = (this.event && this.event.date_end) || (new Date((new Date().getTime() + 86400000)));
    return date.toISOString();
  }

  public get importDialogTitle(): string {
    let keyName: string;
    const translKeyPrefix = 'googleCalendarImportDialogScreens.';
    switch (this.importDialogActiveScreen) {
      case GCalImportDialogScreens.FETCHING_FAILED:
        keyName = 'FETCHING_FAILED';
        break;
      case GCalImportDialogScreens.FOUND_GCAL_EVENTS:
        keyName = 'FOUND_GCAL_EVENTS';
        break;
      case GCalImportDialogScreens.SETTING_UNAVAILABLE_SLOTS:
        keyName = 'SETTING_UNAVAILABLE_SLOTS';
        break;
      case GCalImportDialogScreens.FINISHED_SETTING_UNAVAILABLE_SLOTS:
        keyName = 'SETTING_UNAVAILABLE_SLOTS';
        break;
      case GCalImportDialogScreens.FETCHING_GCAL_EVENTS:
      default:
        keyName = 'FETCHING_GCAL_EVENTS';
    }
    return this.$t(translKeyPrefix + keyName) as string;
  }

  public created(): void {
    this.createTimeSlots();
  }

  public mounted(): void {
    this.addExternalScripts();
    this.requestingTimeSlotParamsIndex = 0;
  }

  public beforeDestroy(): void {
    this.removeExternalScripts();
  }

  @Watch('isSettingsMenuVisible')
  private onIsSettingsMenuVisibleChange(): void {
    this.temporaryIgnoreClickOutside();
    document.removeEventListener('click', this.clickOutside);
    if (this.isSettingsMenuVisible) {
      document.addEventListener('click', this.clickOutside);
    }
  }

  public onSettingsItemClick(menuItem: TTimeSlotMenuItem): void {
    const menuItemName: string = menuItem.name;
    this.hideAllSubmenus();
    this.showSubmenuByMenuItem(menuItem);

    switch (menuItemName) {
      case 'googleCalendarFeatures':
        break;
      case 'googleCalendarImportBusySlots':
        this.onGoogleCalendarImportBusySlotsClick();
        break;
      default:
        break;
    }
  }

  public hideAllSubmenus(): void {
    this.menuItems.forEach(item => {
      if (item.isSubmenuOpen) {
        item.isSubmenuOpen = false;
      }
    });
  }

  public showSubmenuByMenuItem(menuItem: TTimeSlotMenuItem): void {
    if (menuItem.isSubmenuOpen === false) {
      menuItem.isSubmenuOpen = true;
    }
  }

  public onGoogleCalendarImportBusySlotsClick(): void {
    if (!this.isGoogleAccessGiven) {
      this.onAuthorizeClick();
      return;
    }
    this.onRefreshClick();
  }

  public onSettingsClick(): void {
    this.onGoogleCalendarImportBusySlotsClick();
    this.isSettingsMenuVisible = !this.isSettingsMenuVisible;
  }

  public temporaryIgnoreClickOutside(): void {
    this.ignoreClickOutside = true;
    setTimeout(() => {
      this.ignoreClickOutside = false;
    }, 200);
  }

  public clickOutside(event: MouseEvent): void {
    if (this.ignoreClickOutside || !this.isSettingsMenuVisible) {
      return;
    }

    let isChildOfMenu = false;
    let el = event.target as HTMLElement;

    if (!el || !el.parentNode) {
      return;
    }

    while (el.parentNode
      && (el.parentNode as HTMLElement).tagName
      && ((el.parentNode as HTMLElement).tagName.toUpperCase() !== 'BODY')
    ) {
      if ((el.parentNode as HTMLElement).classList.contains('settings-menu')) {
        isChildOfMenu = true;
        break;
      }
      el = el.parentNode as HTMLElement;
    }

    if (!(event.target as HTMLElement).classList.contains('settings-menu')
      && (isChildOfMenu === false)
    ) {
      this.hideAllSubmenus();
      this.isSettingsMenuVisible = false;
    }
  }

  public addExternalScripts(): void {
    this.googleScriptSrcList.forEach(this.addGoogleScript);
  }

  public addGoogleScript(scriptSrc: string): void {
    const s: HTMLScriptElement = document.createElement('script');
    const scriptId: string = this.getScriptIdFromSrc(scriptSrc);
    s.id = scriptId;
    s.src = scriptSrc;
    s.defer = true;
    s.async = true;
    switch (scriptSrc) {
      case GAPI_SCRIPT_SRC:
        s.onload = this.onGapiLoaded;
        break;
      case GIS_SCRIPT_SRC:
        s.onload = this.onGisLoaded;
        break;
      default:
        break;
    }
    if (!document.getElementById(scriptId)) {
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(s);
    }
  }

  public onGisLoaded(): void {
    const G: any = (window as any).google;
    if (!G || !G.accounts || !G.accounts.oauth2) {
      return;
    }
    this.tokenClient = G.accounts.oauth2.initTokenClient({
      client_id: this.CLIENT_ID,
      scope: this.SCOPES,
      callback: this.onTokenClientCallback,
    }) as IGoogleTokenClient;
    // console.log('TC', this.tokenClient);
    this.isGoogleIdentityServiceInitialized = true;
    this.maybeEnableButtons();
  }

  public getScriptIdFromSrc(scriptSrc: string): string {
    return scriptSrc.replace(/[^a-zA-Z0-9]+/g, '_');
  }

  public removeExternalScripts(): void {
    this.googleScriptSrcList.forEach(this.removeGoogleScript);
  }

  public removeGoogleScript(scriptSrc: string): void {
    const victim = document.getElementById(this.getScriptIdFromSrc(scriptSrc));
    if (victim) {
      victim.parentNode.removeChild(victim);
    }
  }

  public onGapiLoaded(): void {
    (window as any).gapi.load('client', this.initializeGapiClient);
  }

  public async initializeGapiClient(): Promise<void> {
    const gapiClient: { init: (params: any) => void } = ((window as any).gapi && (window as any).gapi.client) || null;
    if (!gapiClient) {
      return;
    }

    await gapiClient.init({
      apiKey: this.API_KEY,
      discoveryDocs: [this.DISCOVERY_DOC],
    });
    this.isGapiInitialized = true;
    this.maybeEnableButtons();
  }

  public maybeEnableButtons(): void {
    if (this.isGapiInitialized && this.isGoogleIdentityServiceInitialized) {
      this.isGoogleAuthorizeButtonVisible = true;
    }
  }

  public async onTokenClientCallback(response: IGoogleTokenClientCallbackResponse): Promise<void> {
    if (response.error !== undefined) {
      this.previousTokenCallbackResponse = null;
      throw (response);
    }
    this.previousTokenCallbackResponse = response;
    this.isGoogleAuthorizeButtonVisible = false;
    this.isGoogleAccessGiven = true;
    await this.findGoogleCalendarEvents();
  }

  public onAuthorizeClick(): void {
    const gapiClient: { getToken: () => any } = ((window as any).gapi && (window as any).gapi.client) || null;
    if (gapiClient && gapiClient.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({prompt: ''});
    }
  }

  public async onRefreshClick(): Promise<void> {
    if (this.isGoogleAccessGiven) {
      try {
        await this.tokenClient.callback(this.previousTokenCallbackResponse);
      } catch {
        this.onGisLoaded();
        return;
      }
    }

    await this.findGoogleCalendarEvents();
  }

  /*
    https://developers.google.com/calendar/api/v3/reference/events/list
   */
  public async findGoogleCalendarEvents(): Promise<void> {
    this.showImportDialog();
    this.importDialogActiveScreen = GCalImportDialogScreens.FETCHING_GCAL_EVENTS;

    let response: any;
    try {
      const request: any = {
        calendarId: 'primary',
        timeMin: this.timeMinFormatted,
        timeMax: this.eventDateEndFormatted,
        showDeleted: false,
        singleEvents: true,
        maxResults: this.getMaxResultsForGoogleCalendarRequest(),
        orderBy: 'startTime',
      };

      const gapiClient: any = ((window as any).gapi && (window as any).gapi.client) || null;
      response = (gapiClient && gapiClient.calendar && gapiClient.calendar.events && await gapiClient.calendar.events.list(request)) || null;
    } catch (err) {
      this.importErrorMessage = (err && err.message) || 'No specific error message available, unfortunately.';
      this.foundGoogleCalendarEvents = [];
      this.importDialogActiveScreen = GCalImportDialogScreens.FETCHING_FAILED;
      return;
    }

    this.foundGoogleCalendarEvents = ((response && response.result && response.result.items) || []) as TGoogleCalendarEvent[];

    this.importDialogActiveScreen = GCalImportDialogScreens.FOUND_GCAL_EVENTS;
  }

  public getMaxResultsForGoogleCalendarRequest(): number {
    const eventDateStart: Date = new Date(this.event.date_start);
    const eventDateEnd: Date = new Date(this.event.date_end);
    const diffInSeconds: number = (eventDateEnd.getTime() - eventDateStart.getTime()) / 1000;
    const diffInHalfHours: number = diffInSeconds / 3600 * 2;
    return Math.ceil(diffInHalfHours);
  }

  public showImportDialog(): void {
    this.isImportDialogVisible = true;
  }

  public hideImportDialog(): void {
    this.isImportDialogVisible = false;
    this.importDialogActiveScreen = GCalImportDialogScreens.FETCHING_GCAL_EVENTS;
    this.requestingTimeSlotParamsIndex = 0;
  }

  public onSignOutFromCalendarClick(): void {
    const token: any = (window as any).gapi.client.getToken();
    if (token !== null) {
      ((window as any).google.accounts.oauth2.revoke as (accessToken: any) => void)(token.access_token);
      (window as any).gapi.client.setToken('');
      this.isGoogleAccessGiven = false;
      this.isGoogleAuthorizeButtonVisible = true;
    }
  }

  public onSetBusySlotsClick(): void {
    this.importDialogActiveScreen = GCalImportDialogScreens.SETTING_UNAVAILABLE_SLOTS;
    this.requestQueueParams = this.getRequestQueueParams();
    this.startThrottledRequests();
  }

  public async startThrottledRequests(): Promise<void> {
    await this.requestMeeting(this.requestQueueParams[this.requestingTimeSlotParamsIndex]);
    this.requestingTimeSlotParamsIndex++;
    if (this.requestingTimeSlotParamsIndex >= this.requestQueueParams.length) {
      this.importDialogActiveScreen = GCalImportDialogScreens.FINISHED_SETTING_UNAVAILABLE_SLOTS;
      this.requestingTimeSlotParamsIndex = 0;
      return;
    }
    const throttleDelay: number = this.requestQueueParams.length <= 10 ? 150 : 333;
    window.setTimeout(this.startThrottledRequests, throttleDelay);
  }

  public getRequestQueueParams(): TRequestMeetingParams[] {
    return (this.timeSlots // TODO: filter out timeSlots that have non-canceled meetings
      .map(timeSlot => {
        const gCalEventForTimeSlot = this.getGCalEventForTimeSlot(timeSlot);
        if (!gCalEventForTimeSlot) {
          return null;
        }
        return {
          event_id: this.eventId,
          user_id: this.userId,
          date_start: DateTimeHelper.dateToApiDate(timeSlot.dateStart),
          date_end: DateTimeHelper.dateToApiDate(timeSlot.dateEnd),
          title: (gCalEventForTimeSlot && gCalEventForTimeSlot.htmlLink) || FALLBACK_GCAL_TIME_SLOT_TITLE,
        } as TRequestMeetingParams;
      })
      .filter(x => x)) || [];
  }

  public createTimeSlots(): void {
    this.timeSlots = [];
    const event = this.event;
    if (!event
      || !event.date_start
      || !event.date_end
    ) {
      return;
    }

    const now = new Date();
    let slotsDateStart: Date = event.date_start;
    let needsToStartAtMidnight = true;
    if (now.getTime() >= event.date_start.getTime()) {
      slotsDateStart = now;
      needsToStartAtMidnight = false;
    }
    const eventDateEnd = event.date_end;
    if (slotsDateStart.getTime() >= eventDateEnd.getTime()) {
      return;
    }

    let iteratedDate = new Date(slotsDateStart);
    if (needsToStartAtMidnight) {
      iteratedDate.setHours(0, 0, 0, 0);
    } else {
      // This way we can cut off unneeded timeslots that have already passed since today's beginning
      iteratedDate.setMinutes(0, 0, 0);
    }

    const uniqueDates: { [dateKey: string]: boolean } = {};

    while (iteratedDate.getTime() < eventDateEnd.getTime()) {
      const currentTimeDateKey = DateTimeHelper.getFullDate(iteratedDate);
      const nextTime = (new Date(iteratedDate.getTime() + 30 * 60 * 1000));
      if (uniqueDates[currentTimeDateKey]) {
        iteratedDate = nextTime;
        continue;
      }

      this.timeSlots.push({
        dateStart: new Date(iteratedDate),
        dateEnd: new Date(nextTime),
        meeting: null, // N.B. Too lazy to get a real meeting here // TODO: get a meeting or remove this todo?
      });

      uniqueDates[currentTimeDateKey] = true;
      iteratedDate = new Date(nextTime);
    }
  }

  public isTimeSlotBusyInGoogleCalendar(timeSlot: TTimeSlot): boolean {
    return !!this.foundGoogleCalendarEvents.find((googleCalEvent: TGoogleCalendarEvent) => {
      const slotStartStamp = (new Date(timeSlot.dateStart.toUTCString())).getTime();
      const calEventStartStamp = (new Date((new Date(googleCalEvent.start.dateTime || googleCalEvent.start.date)).toUTCString())).getTime();
      const slotEndStamp = (new Date(timeSlot.dateEnd.toUTCString())).getTime();
      const calEventEndStamp = (new Date((new Date(googleCalEvent.end.dateTime || googleCalEvent.end.date)).toUTCString())).getTime();
      const isSlotStartInsideCalEvent = (slotStartStamp >= calEventStartStamp) && (slotStartStamp <= calEventEndStamp);
      const isSlotEndInsideCalEvent = (slotEndStamp > calEventStartStamp) && (slotEndStamp <= calEventEndStamp);

      return isSlotStartInsideCalEvent || isSlotEndInsideCalEvent;
    });
  }

  public getGCalEventForTimeSlot(timeSlot: TTimeSlot): TGoogleCalendarEvent {
    return this.foundGoogleCalendarEvents.find((googleCalEvent: TGoogleCalendarEvent) => {
      const slotStartStamp = (new Date(timeSlot.dateStart.toUTCString())).getTime();
      const calEventStartStamp = (new Date((new Date(googleCalEvent.start.dateTime || googleCalEvent.start.date)).toUTCString())).getTime();
      const slotEndStamp = (new Date(timeSlot.dateEnd.toUTCString())).getTime();
      const calEventEndStamp = (new Date((new Date(googleCalEvent.end.dateTime || googleCalEvent.end.date)).toUTCString())).getTime();
      const isSlotStartInsideCalEvent = (slotStartStamp >= calEventStartStamp) && (slotStartStamp <= calEventEndStamp);
      const isSlotEndInsideCalEvent = (slotEndStamp > calEventStartStamp) && (slotEndStamp <= calEventEndStamp);

      return isSlotStartInsideCalEvent || isSlotEndInsideCalEvent;
    });
  }

}
