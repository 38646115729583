








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class EwIconChat extends Vue {
  @Prop({ type: Boolean, default: false }) disabled: boolean;
}
