





import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-meeting-star',
})
export default class IconMeetingStar extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly filled: boolean;

}
