













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EwIconAcceptReject extends Vue {
  @Prop({ type: Boolean, default: false }) accept: boolean;
}
