


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { Action } from 'vuex-class';
import DateTimeHelper from '@/_helpers/date-time.helper';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TEventDay, TMeeting } from '@/_types/meeting/meeting.type';
import { TContact } from '@/_types/contact.type';
import IconMeetingAccept from '@/_modules/icons/components/meetings/icon-meeting-accept.vue';
import IconMeetingPlus from '@/_modules/icons/components/meetings/icon-meeting-plus.vue';
import IconMeetingReject from '@/_modules/icons/components/meetings/icon-meeting-reject.vue';
import IconMeetingShare from '@/_modules/icons/components/meetings/icon-meeting-share.vue';
import IconMeetingStar from '@/_modules/icons/components/meetings/icon-meeting-star.vue';
import IconMeetingStart from '@/_modules/icons/components/meetings/icon-meeting-start.vue';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import { TUpdateMeetingContactFavoriteParams } from '@/_modules/meetings/store/meetings.store';
import Person from '@/_modules/contacts/components/person/person.vue';
import EwIconAcceptReject from '@/_modules/icons/components/meetings/ew-icon-accept-reject.vue';
import EwIconChat from '@/_modules/icons/components/meetings/ew-icon-chat.vue';

type TTimeSlot = {
  dateStart: string;
  dateEnd: string;
  meeting?: TMeeting;
}

@Component({
  components: {
    IconMeetingAccept,
    IconMeetingPlus,
    IconMeetingReject,
    IconMeetingShare,
    IconMeetingStar,
    IconMeetingStart,
    EwIconAcceptReject,
    Person,
    EwIconChat
  },
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      myself: 'promoPageStore/contact',
      meetingsCount: 'notificationsStore/meetingsCount',
      noticedMeetingsCount: 'notificationsStore/noticedMeetingsCount',
      getMeetingsByUserId: 'meetingsStore/getMeetingsByUserId',
      lastError: 'meetingsStore/getLastError',
      // getSelectedDate: 'meetingsStore/getSelectedDate'
      isDeclineMeetingConfirmed: 'meetingsStore/isDeclineMeetingConfirmed',
    }),
    ...mapState('meetingsStore', {
      meetingsByUserId: 'meetingsByUserId'
    })
  }
})

export default class MeetingsRequests extends Vue {

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string }) => void;
  @Action('meetingsStore/requestUserMeetings') requestUserMeetings: (params: { userId: number; force?: boolean }) => Promise<TMeeting[]>;

  private userId: number = null;
  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly getMeetingsByUserId: Function;
  public readonly myself: TContact;
  public readonly lastError: Error;
  public eventDays: TEventDay[] = [];
  public readonly meetingsByUserId: { [userId: number]: TMeeting[] };
  public meetingSlots: TMeeting[] = [];
  public isDeclineMeetingConfirmed: false;
  public tempEvent: Event;
  public tempMeeting: TMeeting;

  @Prop({ type: Date, default: null })
  public selectedDate: Date;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public mounted(): void {
    this.openContactCardFromUrl();
  }

  public openContactCardFromUrl(): void {
    const contactIdParam: number = parseInt(this.$route.params.contact_id, 10) || null;
    if (contactIdParam) {
      this.openContactCard({
        contactId: contactIdParam,
        startupTabName: 'messages',
      });
    }
  }

  public get getNoCanceledMeetings(): TMeeting[] {
    if (!this.user) {
      return [];
    }
    const userId = this.user.id;
    return this.getMeetingsByUserId(userId).filter((item: TMeeting) => {
      return item.status !== MeetingStatus.Canceled && item.status === MeetingStatus.Unconfirmed;

    }).sort((a: TMeeting, b: TMeeting) => {
      if (a.date_start < b.date_start) {
        return -1;
      } else if (a.date_start > b.date_start) {
        return 1;
      }
      return 0;
    });
  }

  public get selectedDayMeetingList(): TMeeting[] {
    const noCanceledMeetings = (this.getNoCanceledMeetings || []);
    if (!noCanceledMeetings.length) {
      return [];
    }
    return noCanceledMeetings.filter((item: TMeeting) => {

      return item;
    });
  }

  public get myFullName(): string {
    if (!this.myself) {
      return '';
    }

    if (this.myself.fullName) {
      return this.myself.fullName;
    } else {
      const result: string = [this.myself.name, this.myself.surname].filter(x => x).join(' ').trim();
      if (result) {
        return result;
      }
    }

    return '';
  }

  public get myAvatarSrc(): string {
    if (this.myself && this.myself.photo_url) {
      return this.myself.photo_url;
    }

    return '';
  }

  @Watch('event', { immediate: true })
  private onEventChanged(): void {
    if (!this.event) {
      return;
    }

    this.getEventDays();
  }

  @Watch('selectedDate', { immediate: true })
  private onSelectedDateChanged(): void {
    this.meetingSlots = this.selectedDayMeetingList;
  }

  @Watch('meetingsByUserId')
  private onMeetingsByUserIdChanged(): void {
    this.meetingSlots = this.selectedDayMeetingList;
  }

  @Watch('isDeclineMeetingConfirmed')
  private onDeclineMeetingChanged(): void {
    if (this.isDeclineMeetingConfirmed) {
      this.cancelMeeting(this.tempEvent, this.tempMeeting);
    }
  }

  @Watch('meetingsCount')
  private onMeetingsCountChanged(): void {
    if (!this.user) {
      return;
    }

    this.userId = this.user.id;

    this.$store.dispatch('meetingsStore/requestUserMeetings', {
      userId: this.userId,
      force: true,
    });
  }

  //
  // @Watch('selectedDate')
  // private onSelectedDateChanged(): void {
  //   console.log(this.selectedDate);
  // }

  public getEventDays(): void {
    if (!this.event) {
      return;
    }

    const day = 60 * 60 * 24 * 1000;
    const dateStart = this.event.date_start;
    const dateEnd = this.event.date_end;
    let iteratedDate = dateStart; // Объект JS-даты для цикла

    for (let i = 0; i <= DateTimeHelper.getDateDiff(new Date(dateEnd), new Date(dateStart)); i++) {

      const eventDay: TEventDay = {
        date: iteratedDate,
        dayNumber: this.$moment(iteratedDate).format('D').padStart(2, '0'),
        badgeNotification: false
      };
      this.eventDays = [...this.eventDays, eventDay];
      iteratedDate = new Date(iteratedDate.getTime() + day);
    }
  }

  // slots
  public setSlotTime(dateValue: Date, hours: number, minutes: number, seconds: number): Date {
    const date = new Date(dateValue);
    date.setHours(hours, minutes, seconds);
    return date;
  }

  public addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
  }

  public displayTime(date: Date): string {
    return this.$moment(date).format('H:mm');
  }

  public toggleContactFavorite(contact: TContact): void {

    const payload = {
      eventId: this.$route.params.eventId,
      contactId: contact.id,
    };

    const contactFavoritePayload: TUpdateMeetingContactFavoriteParams = {
      isFavorite: !contact.is_favorite,
      contactId: contact.id
    };

    if (contact.is_favorite) {
      this.$store.dispatch('meetingsStore/updateContactFavorite', contactFavoritePayload);
      this.$store.dispatch('contactsStore/removeFavContact', payload);
    } else {
      this.$store.dispatch('meetingsStore/updateContactFavorite', contactFavoritePayload);
      this.$store.dispatch('contactsStore/addFavContact', payload);
    }
  }

  public messageContact(meeting: TMeeting): void {
    const targetRouteName = 'promo-page-calendar';
    const contactId = meeting.contact.id;

    // avoiding redundant navigation
    if (parseInt(this.$route.params.contact_id, 10) !== contactId) {
      this.$router.push({
        name: targetRouteName,
        params: {
          contact_id: contactId.toString()
        },
      }).catch(() => { /* ignore */ });
    }

    this.openContactCard({
      contactId: contactId,
      startupTabName: 'messages',
    });
  }

  public showDeclineConfirmation(event: Event, meeting: TMeeting): void {
    this.tempEvent = event;
    this.tempMeeting = meeting;
    this.$store.dispatch('meetingsStore/setMeetingDeclinePopupVisible', true);
  }

  public async cancelMeeting(event: Event, meeting: TMeeting): Promise<void> {
    if (!(meeting && meeting.id)) {
      await this.$store.dispatch('meetingsStore/setMeetingDeclinePopupVisible', false);
      await this.$store.dispatch('meetingsStore/setIsDeclineMeetingConfirmed', false);
      return;
    }

    this.$store.dispatch('notificationsStore/setNoticedMeetings', [meeting.id]);

    let meetingElement: HTMLElement = event.target as HTMLElement;
    while (meetingElement.tagName.toLowerCase() !== 'body') {
      if (meetingElement.classList.contains('time-slot')) {
        break;
      }
      meetingElement = meetingElement.parentNode as HTMLElement;
    }

    if (meetingElement.tagName.toLowerCase() === 'body') {
      return;
    }

    meetingElement.classList.add('time-slot-processing');

    const cancelMeetingResponse = await this.$store.dispatch('meetingsStore/cancelMeeting', {
      event_id: this.eventId,
      meeting_id: meeting.id
    });

    meetingElement.classList.remove('time-slot-processing');

    // TODO: do not put .errors in TMeeting, it does not belong there
    // Backend error displaying
    // If response is undefined, or response.status !== 202 or if error field is present, show:
    // — response.error as is  OR
    // — default message as is
    if (!cancelMeetingResponse || cancelMeetingResponse.status !== 202 || cancelMeetingResponse.error) {
      meeting.errors = meeting.errors || [];
      meeting.errors = [...meeting.errors, { text: cancelMeetingResponse.error ? cancelMeetingResponse.error : this.$t('errors.meetingGeneralError') }];
    }
    this.meetingSlots = [...this.meetingSlots];

    if (cancelMeetingResponse.status && cancelMeetingResponse.status === 202) {

      const meetingDate = DateTimeHelper.getDateWithoutHoursMinutes(meeting.date_start);
      for (let i = 0; i < this.eventDays.length; i++) {
        if (DateTimeHelper.getDateWithoutHoursMinutes(this.eventDays[i].date) === meetingDate) {
          this.eventDays[i].badgeNotification = false;
          break;
        }
      }

    }
    this.meetingSlots = this.selectedDayMeetingList;
    this.$store.dispatch('meetingsStore/setMeetingDeclinePopupVisible', false);
    await this.$store.dispatch('meetingsStore/setIsDeclineMeetingConfirmed', false);
  }

  public async confirmMeeting(event: Event, meeting: TMeeting): Promise<void> {
    if (!(meeting && meeting.id)) {
      return;
    }

    this.$store.dispatch('notificationsStore/setNoticedMeetings', [meeting.id]);

    // Visual feedback: find .time-slot element up the tree from the pressed button
    let meetingElement: HTMLElement = (event.target as HTMLElement);
    while (meetingElement.tagName.toLowerCase() !== 'body') {
      if (meetingElement.classList.contains('time-slot')) {
        break;
      }
      meetingElement = (meetingElement.parentNode as HTMLElement);
    }

    if (meetingElement.tagName.toLowerCase() === 'body') {
      return;
    }

    // Visual feedback: add a CSS class
    meetingElement.classList.add('time-slot-processing');

    const confirmMeetingResponse = await this.$store.dispatch('meetingsStore/confirmMeeting', {
      event_id: this.eventId,
      meeting_id: meeting.id
    });

    // Visual feedback: remove that CSS class
    meetingElement.classList.remove('time-slot-processing');

    // Backend error displaying
    // If response is undefined, or response.status !== 202 or if error field is present, show:
    // — response.error as is  OR
    // — default message as is

    if (!confirmMeetingResponse || confirmMeetingResponse.status !== 202 || confirmMeetingResponse.error) {
      meeting.errors = meeting.errors || [];
      meeting.errors = [...meeting.errors, {
        text: confirmMeetingResponse.error ?
          (confirmMeetingResponse.error === 'this user\'s time is taken' ?
            this.$t('meetings.errors.slotIsBusy') : confirmMeetingResponse.error) :
          this.$t('errors.meetingGeneralError')
      }];
    }
    this.meetingSlots = [...this.meetingSlots];

    // Success routines: adding into corresponding time slot,
    // animation
    if (confirmMeetingResponse && confirmMeetingResponse.status && confirmMeetingResponse.status === 202) {
      meeting.isFading = true;
      setTimeout(function () {
        meeting.isFolding = true;
      }, 250);
    }
  }

  public clearMeetingErrors(meeting: TMeeting): void {
    meeting.errors = null;
    this.meetingSlots = [...this.meetingSlots];
  }

  public meetingRequestsDate(meeting: TMeeting): string {
    return this.$moment(meeting.date_start).format(DateTimeFormat.DAY_NAME_DATE_MONTH_UK); // вынести в хелпер
  }

  public isMeetingRequestDayDifferentFromPrevious(meeting: TMeeting, index: number): boolean {
    if (index === 0) {
      return true;
    }

    const prevMeetingDate = new Date(this.meetingSlots[index - 1].date_start);
    const meetingDate = new Date(meeting.date_start);
    return +prevMeetingDate.setHours(0, 0, 0, 0) !== +meetingDate.setHours(0, 0, 0, 0);
  }

  public indicatorType(meeting: TMeeting): string {
    if (meeting.communication_type !== 'offline' && !this.isTimeSlotPast(meeting)) {
      return 'online';
    } else if (meeting.communication_type === 'offline' && !this.isTimeSlotPast(meeting)) {
      return 'offline';
    } else {
      return 'expired';
    }
  }

  public isTimeSlotPast(meeting: TMeeting): boolean {
    return +meeting.date_start < (new Date()).getTime() - (1000 * 60 * 30);
  }

}

