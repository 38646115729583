import { render, staticRenderFns } from "./ew-icon-accept-reject.vue?vue&type=template&id=997e7724&scoped=true&"
import script from "./ew-icon-accept-reject.vue?vue&type=script&lang=ts&"
export * from "./ew-icon-accept-reject.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997e7724",
  null
  
)

export default component.exports